import t, { useIntl } from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from './Theme';
import ThemeLink from './Theme/ThemeLink';
import MaxWidth from './Layout/MaxWidth';
import BG404 from '../images/404.png';
import BG404Mobile from '../images/404-mobile.png';
import HelloRetail, { helloRetailKeys } from './Theme/HelloRetail';

const NotFoundWrapper = css`
  position: relative;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 2rem;
  background-color: ${theme.colors.black};
  background-image: url(${BG404});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${theme.colors.white};

  ${theme.below.sm} {
    background-image: url(${BG404Mobile});
    padding-bottom: 92%;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  ${theme.below.sm} {
    padding: 0 0 ${theme.space[4]};
    justify-content: flex-end;
  }
`;

const TextBox = css`
  padding: ${theme.space[4]};
  max-width: 480px;

  h1 {
    text-transform: uppercase;
    margin-bottom: ${theme.space[1]};
  }

  ${theme.below.sm} {
    padding: 0 ${theme.space[2]};
  }
`;

const Actions = css`
  display: flex;
  margin-top: ${theme.space[4]};

  ${theme.below.sm} {
    justify-content: center;

    a {
      width: 100%;
    }
  }
`;

const buttonUrlStart = '/';

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <>
      <div className={NotFoundWrapper}>
        <StyledMaxWidth>
          <div className={TextBox}>
            <h1>{t('Oops!')}</h1>
            <p>
              {t(
                "We can't find the page you're looking for. Go back to the start page and try again."
              )}
            </p>
            <div className={Actions}>
              <ThemeLink link={buttonUrlStart} isButton secondary>
                {t('To startpage')}
              </ThemeLink>
            </div>
          </div>
        </StyledMaxWidth>
      </div>
      <HelloRetail
        helloRetailBoxes={[
          {
            key: helloRetailKeys.RECOMMENDED_FOR_YOU,
            title: intl('Recommended for you')
          },
          {
            key: helloRetailKeys.POPULAR_RIGHT_NOW,
            title: intl('Popular products')
          }
        ]}
      />
    </>
  );
};

export default NotFoundPage;
